import contactApi from "@/api/contact";

const state = {
    data: null,
    isLoading: false,
    error: null,
};

export const mutationTypes = {
    getContactListStart: '[contact list] Get contact list start',
    getContactListSuccess: '[contact list] Get contact list success',
    getContactListFailure: '[contact list] Get contact list failure',
}

export const actionTypes = {
    getContactList: '[contact list] Get contact list'
}

const mutations = {
    [mutationTypes.getContactListStart](state) {
        state.isLoading = true
        state.data = null
    },
    [mutationTypes.getContactListSuccess](state, payload) {
        state.isLoading = false
        state.data = payload
    },
    [mutationTypes.getContactListFailure](state) {
        state.isLoading = false
    }
}

const actions = {
    [actionTypes.getContactList](context) {
        return new Promise(resolve => {
            context.commit(mutationTypes.getContactListStart)
            contactApi.getAllContacts()
                .then(response => {
                    context.commit(mutationTypes.getContactListSuccess, response.data.data)
                    resolve(response.data.data)
                })
                .catch(() => {
                    context.commit(mutationTypes.getContactListFailure)
                })
        })
    }
}
export default {
    state,
    actions,
    mutations
}