<template>
  <v-alert type="warning" variant="tonal" class="mb-4" >
    <template v-slot:text>
      <ul class=" text-caption">
        <li v-for="errorMessage in errorMessages" :key="errorMessage">
          {{ errorMessage.message }}
        </li>
      </ul>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "AppValidationErrors",
  props: {
    validationErrors: {
      type: Object,
      required: true
    }
  },
  computed: {
    errorMessages() {
      return this.validationErrors
    }
  }
}
</script>