<template>
  <v-app>

    <app-topbar></app-topbar>
    <v-main class="bg-grey-lighten-5">
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import "@/assets/global.css"
import {actionTypes} from "@/store/modules/auth";
import AppTopbar from "@/components/Topbar";


export default {
  name: 'App',
  components: {AppTopbar},
  methods: {
    async fetchData() {
      this.$store.dispatch(actionTypes.getCurrentUser)

    },
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>
