<template>
  <v-app-bar color="teal-darken-4">
    <v-icon
        size="large"
        class="ms-4"
    >
      mdi mdi-email-outline
    </v-icon>
    <v-app-bar-title>Печать на конвертах</v-app-bar-title>
<div v-if="!isLoggedIn && !isLoading">
    <v-btn
           @click="loginDialog=true"
           prepend-icon="mdi mdi-account"
           variant="plain"
           :ripple="false"
           class="text-none"
    >
      Вход
    </v-btn>
    <v-dialog
        v-model="loginDialog"
        max-width="500px"
    >
      <app-login-form
          @openSignupDialog="[this.loginDialog=false, this.signupDialog = true]"
          @closeLoginDialog="[this.loginDialog=false]"></app-login-form>
    </v-dialog>

    <v-divider vertical
               inset
               color="success"
    ></v-divider>
    <v-btn
           @click="signupDialog=true"
           variant="plain"
           :ripple="false"
           class="text-none"
    >
      Регистрация
    </v-btn>
    <v-dialog
        v-model="signupDialog"
        max-width="500px"
    >
      <app-signup-form
          @openLoginDialog="[this.signupDialog=false, this.loginDialog = true]"
          @closeSignupDialog="[this.signupDialog=false]"></app-signup-form>
    </v-dialog>
</div>
    <div class="text-center">
      <v-menu
          v-if="isLoggedIn && !isLoading"
          v-model="menu"
          :close-on-content-click="false"
          location="end"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              variant="plain"
              :ripple="false"
              class="text-none font-weight-regular"
              prepend-icon="mdi-emoticon-happy"
              v-bind="props"
          >
            {{currentUser.username}}
          </v-btn>

        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item
                size="x-large"
                :title="currentUser.username"
                :subtitle="currentUser.email"
            >
              <template v-slot:prepend>
                <v-icon size="x-large">mdi-emoticon-happy-outline</v-icon>
              </template>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
                link
                prepend-icon="mdi mdi-cog"
            >
              <v-list-item-title>
                Настройки
              </v-list-item-title>
            </v-list-item>

            <v-list-item
                class="mb-2"
                link
                prepend-icon="mdi mdi-book-account-outline"
            >
              <v-list-item-title>
               Адресная книга
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
                link
                @click="logout"
                class="justify-center"

            >
              <v-list-item-title
                  class="text-red-accent-1"
              >
                Выйти из аккаунта
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>

  </v-app-bar>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {actionTypes, getterTypes, mutationTypes} from "@/store/modules/auth";
import AppLoginForm from "@/components/LoginForm";
import AppSignupForm from "@/components/SignUpForm";

export default {
  name: "AppTopbar",
  components: {AppSignupForm, AppLoginForm},
  data: () => ({
    loginDialog: false,
    signupDialog: false,
    menu: false,
    items: [
      {text: 'My Files', icon: 'mdi-folder'},
      {text: 'Shared with me', icon: 'mdi-account-multiple'},
      {text: 'Starred', icon: 'mdi-star'},
      {text: 'Recent', icon: 'mdi-history'},
      {text: 'Offline', icon: 'mdi-check-circle'},
      {text: 'Uploads', icon: 'mdi-upload'},
      {text: 'Backups', icon: 'mdi-cloud-upload'},
    ],
  }),
  computed: {
    ...mapGetters({
      currentUser: getterTypes.currentUser,
      isLoggedIn: getterTypes.isLoggedIn,
      isAnonymous: getterTypes.isAnonymous,
    }),
    ...mapState({
      isLoading: state => state.auth.isLoading
    })

  },
  watch: {
    loginDialog(val) {
      if (!val) {
        this.$store.commit(mutationTypes.clearError)
      }
    },
    signupDialog(val) {
      if (!val) {
        this.$store.commit(mutationTypes.clearError)
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(actionTypes.logout)
    },
  }

}
</script>

<style scoped>

</style>