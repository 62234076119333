import contactApi from "@/api/contact";

const state = {
  data: null,
  isLoading: false,
  error: null,

  isSubmitting: false,
  validationErrors: null,
};

export const mutationTypes = {
  getContactStart: "[contact] Get contact start",
  getContactSuccess: "[contact] Get contact success",
  getContactFailure: "[contact] Get contact failure",

  deleteContactStart: "[contact] Delete contact start",
  deleteContactSuccess: "[contact] Delete contact success",
  deleteContactFailure: "[contact] Delete contact failure",

  createContactStart: "[contact] Create contact start",
  createContactSuccess: "[contact] Create contact success",
  createContactFailure: "[contact] Create contact failure",

  updateContactStart: "[contact] Update contact start",
  updateContactSuccess: "[contact] Update contact success",
  updateContactFailure: "[contact] Update contact failure",

  clearValidationError: "[contact] Clear validation error",
};

export const actionTypes = {
  getContact: "[contact] Get contact",
  deleteContact: "[contact] Delete contact",
  createContact: "[contact] Create contact",
  updateContact: "[contact] Update contact",
};

const mutations = {
  [mutationTypes.getContactStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getContactSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getContactFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createContactStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.createContactSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createContactFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.updateContactStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.updateContactSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.updateContactFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.clearValidationError](state) {
    state.validationErrors = null;
  },

  [mutationTypes.deleteContactStart]() {},
  [mutationTypes.deleteContactSuccess]() {},
  [mutationTypes.deleteContactFailure]() {},
};

const actions = {
  [actionTypes.getContact](context, { slug }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getContactStart, slug); // get slug in payload to easy debug
      contactApi
        .getContact(slug)
        .then((contact) => {
          context.commit(mutationTypes.getContactSuccess, contact);
          resolve(contact);
        })
        .catch(() => {
          context.commit(mutationTypes.getContactFailure);
        });
    });
  },
  [actionTypes.deleteContact](context, { slug }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteContactStart, slug); // get slug in payload to easy debug
      contactApi
        .deleteContact(slug)
        .then(() => {
          context.commit(mutationTypes.deleteContactSuccess);
          resolve();
        })
        .catch(() => {
          context.commit(mutationTypes.deleteContactFailure);
        });
    });
  },
  [actionTypes.createContact](context, { contactInput }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.createContactStart);
      contactApi
        .createContact(contactInput)
        .then((contact) => {
          context.commit(mutationTypes.createContactSuccess, contact);
          resolve(contact);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createContactFailure,
            result.response.data.error
          );
        });
    });
  },
  [actionTypes.updateContact](context, { slug, contactInput }) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.updateContactStart);
      contactApi
        .updateContact(slug, contactInput)
        .then((contact) => {
          context.commit(mutationTypes.updateContactSuccess, contact);
          resolve(contact);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.updateContactFailure,
            result.response.data.errors
          );
        });
    });
  },
};
export default {
  state,
  actions,
  mutations,
};
