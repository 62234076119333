<template>
  <div>
    <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
    >

      <v-form v-model="isValidationPassed">
      <div class="text-subtitle-1 text-medium-emphasis">Аккаунт</div>
      <v-text-field
          v-model="email"
          density="compact"
          placeholder="Email адрес"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          :rules="[rules.required]"
      ></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
        Пароль

        <a
            class="text-caption text-decoration-none text-blue"
            rel="noopener noreferrer"
            target="_blank"
        >
          Забыли пароль?</a>
      </div>

      <v-text-field
          v-model="password"
          :append-inner-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
          :type="show ? 'text' : 'password'"
          density="compact"
          placeholder="Введите ваш пароль"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          :rules="[rules.required, rules.password]"
          hint="Не менее 6 символов"
          counter
          @click:append-inner="show = !show"
      ></v-text-field>

        <app-validation-errors
            class="mb-12"
            v-if="validationErrors"
            :validation-errors="validationErrors"/>

        <v-btn
          :disabled="!isValidationPassed"
          block
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          @click="onSubmit"
      >
        Войти
      </v-btn>
      </v-form>
      <v-card-text class="text-center">
        <v-btn
            @click="openSignupDialog"
            variant="text"
            :ripple="false"
            class="text-none font-weight-regular text-blue"
            append-icon="mdi-chevron-right"
        >
          Нет аккаунта? Регистрация
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppValidationErrors from "@/components/ValidationErrors";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/auth";

export default {
  name: "AppLoginForm",
  components: {AppValidationErrors},
  data() {
    return {
      show: false,
      email:'',
      password: '',
      isValidationPassed: false,
      rules: {
        required: value => !!value || 'Обязательное поле',
        password: value => (value && value.length >= 6) || 'Минимум 6 символов',
      },
    }
  },
  computed:
      mapState({
        isSubmitting: state => state.auth.isSubmitting,
        validationErrors: state => state.auth.validationErrors
      }),
  methods: {
    onSubmit()
    {
      this.$store
          .dispatch(actionTypes.login, {
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.closeLoginDialog()
          })
    },
    closeLoginDialog(){
      this.$emit('closeLoginDialog')
    },
    openSignupDialog(){
      this.closeLoginDialog()
      this.$emit('openSignupDialog')
    }
  }
}
</script>