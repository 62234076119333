export function drawContactBlock(
    context,
    text, tab,
    x,
    y,
    lineHeight,
    envelopeFormat,
    blockType,
    blockView,
    ratio,
    index
) {
    context.setLineDash([2 * ratio, 3 * ratio])

    let textBlock = drawTextBlock(
        context,
        text, tab,
        x,
        y,
        lineHeight,
        envelopeFormat,
        blockType,
        blockView,
        ratio
    );

    if (index !== undefined) {
        drawIndexBlock(
            context,
            index,
            textBlock.x,
            textBlock.y,
            lineHeight,
            envelopeFormat,
            blockType,
            blockView,
            ratio
        );
    }
    return textBlock;
}

export function drawEnvelopeBody(canvas, dpi, envelopeFormat) {

    const DPI = dpi || 72;  // need to have a selected dots per unit in this case inches
    const ratio = DPI / 72
    const units = "inch";
    const sizeWidth = envelopeFormat.envelopeSize[units].width;  // canvas intended print size in units = "inch"
    const sizeHeight = envelopeFormat.envelopeSize[units].height;

    let context = canvas.getContext('2d')
    canvas.width = DPI * sizeWidth;
    canvas.height = DPI * sizeHeight;

    canvas.style.width = "100%";

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'rgba(255,255,255)';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.translate(0.5, 0.5)

    return ratio
}

export function drawTextBlockTitle(context, title, x, y, ratio) {
    context.beginPath();
    context.font = `bold ${14 * ratio}px Arial`;
    let tab = context.measureText(title).width
    context.fillText(title, x, y);
    context.stroke();
    context.closePath();
    return tab
}

export function drawStamp(context, envelopeFormat, ratio) {
    context.beginPath();
    context.moveTo(envelopeFormat.stampBlock.x * ratio, envelopeFormat.stampBlock.y * ratio);
    context.lineTo(envelopeFormat.stampBlock.x * ratio + 45 * ratio, envelopeFormat.stampBlock.y * ratio);
    context.lineTo(envelopeFormat.stampBlock.x * ratio + 45 * ratio, envelopeFormat.stampBlock.y + 45 * ratio);
    context.stroke();
    context.closePath();
}

//TODO add method for cutting string without whitespace that longer than fitWidth
function drawTextBlock(
    context,
    text, tab,
    x,
    y,
    lineHeight,
    envelopeFormat,
    blockType,
    blockView,
    ratio
) {
    let fitWidth = envelopeFormat[blockType].width * ratio || 0;
    context.beginPath();
    if (fitWidth <= 0) {
        context.fillText(text, x, y);
        return;
    }

    let words = text.split(" ");
    let currentLine = 0;
    let idx = 1;
    let hasTab = false;
    while (words.length > 0 && idx <= words.length) {
        let str = words.slice(0, idx).join(" ");
        let w
        if (!hasTab) { // add tab to first line
            w = context.measureText(str).width + tab;
        } else {
            w = context.measureText(str).width;
        }
        if (w > fitWidth) { //many lines
            if (idx == 1) {
                idx = 2;
            }
            if (BLOCK_VIEW.text === blockView || BLOCK_VIEW.underline === blockView) {

                if (!hasTab) {
                    drawText(context, words, idx, x + tab, y, lineHeight, currentLine);
                    hasTab = true;
                } else {
                    drawText(context, words, idx, x, y, lineHeight, currentLine);
                }
            }
            if (
                BLOCK_VIEW.lines === blockView ||
                BLOCK_VIEW.underline === blockView
            ) {
                drawLines(context, x, y, lineHeight, currentLine, fitWidth);
            }
            currentLine++;
            words = words.splice(idx - 1);
            idx = 1;
        } else {
            idx++;
        }
        if (BLOCK_VIEW.lines === blockView || BLOCK_VIEW.underline === blockView) {
            drawFirstLine(context, x, y, lineHeight, fitWidth);
        }
    }
    if (idx > 0) // 1 line
        if (!hasTab) {
            context.fillText(words.join(" "), x + tab, y + lineHeight * currentLine);
            hasTab = true;
        } else {
            context.fillText(words.join(" "), x, y + lineHeight * currentLine);
        }

    context.stroke();
    context.closePath();
    return {x: x + fitWidth, y: y + lineHeight * (currentLine + 1)};
}

function drawFirstLine(context, x, y, lineHeight, fitWidth) {
    context.moveTo(x, y + lineHeight / 3);
    context.lineTo(x + fitWidth, y + lineHeight / 3);
}

function drawText(context, words, idx, x, y, lineHeight, currentLine) {
    context.fillText(
        words.slice(0, idx - 1).join(" "),
        x,
        y + lineHeight * currentLine
    );
}

function drawLines(context, x, y, lineHeight, currentLine, fitWidth) {
    context.moveTo(x, y + lineHeight * (currentLine + 1) + lineHeight / 3);
    context.lineTo(
        x + fitWidth,
        y + lineHeight * (currentLine + 1) + lineHeight / 3
    );
}

function drawIndexBlock(
    context,
    index,
    x,
    y,
    lineHeight,
    envelopeFormat,
    blockType,
    blockView,
    ratio
) {
    if (BLOCK_VIEW.empty === blockView) {
        return;
    } else {
        if (BLOCK_TYPE.senderBlock === blockType) {
            x = x - envelopeFormat.indexBlockWidth * ratio;

            if(BLOCK_VIEW.underline === blockView) {
                context.beginPath();
                context.strokeRect(
                    x,
                    y - lineHeight / 1.5,
                    envelopeFormat.indexBlockWidth * ratio,
                    lineHeight
                );
                context.stroke();
                context.closePath();
            }

            context.fillText(index, x + 4 * ratio, y);
            context.stroke()

        }

        if (BLOCK_TYPE.recipientBlock === blockType) {
            if(BLOCK_VIEW.underline === blockView) {
                context.beginPath();
                context.strokeRect(
                    x - envelopeFormat[blockType].width * ratio,
                    y - lineHeight / 1.5,
                    envelopeFormat.indexBlockWidth * ratio,
                    lineHeight
                );
                context.stroke();
                context.closePath();
            }

            context.fillText(index, x - envelopeFormat.recipientBlock.width * ratio + 4 * ratio, y);
            context.stroke()
        }
    }
}

export const BLOCK_TYPE = {
    senderBlock: "senderBlock",
    recipientBlock: "recipientBlock",
};

export const BLOCK_VIEW = {
    text: 0,
    underline: 1,
};

export const ENVELOPE_FORMAT = {
    c5: {
        name: "C5",
        envelopeSize: {
            inch: {
                width: 9,
                height: 6.4,
            },
            mm: {
                width: 229,
                height: 162,
            },
        },
        senderBlock: {
            width: 250,
            x: 30,
            y: 50,
        },
        recipientBlock: {
            width: 293,
            x: 323,
            y: 270,
        },
        indexBlockWidth: 100,
        indexBlock: {
            x: 30,
            y: 400,
        },
        stampBlock: {
            x: 570,
            y: 30
        }
    },
    c65: {
        name: "C65",
        envelopeSize: {
            inch: {
                width: 9,
                height: 4.5,
            },
            mm: {
                width: 229,
                height: 114,
            },
        },
        senderBlock: {
            width: 250,
            x: 30,
            y: 50,
        },
        recipientBlock: {
            width: 293,
            x: 323,
            y: 170,
        },
        indexBlockWidth: 100,
        indexBlock: {
            x: 30,
            y: 280,
        },
        stampBlock: {
            x: 570,
            y: 30
        }
    },
};

export const PAGE_SIZES = {
    a4: {
        portrait: {
            inch: {
                width: 8.27,
                height: 11.69,
            },
            mm: {
                width: 210,
                height: 297,
            },
        },
        landscape: {
            inch: {
                height: 8.27,
                width: 11.69,
            },
            mm: {
                width: 297,
                height: 210,
            },
        },
    },
    c5: {
        portrait: {
            inch: {
                width: 6.4,
                height: 9,
            },
            mm: {
                width: 162,
                height: 229,
            },
        },
        landscape: {
            inch: {
                height: 6.4,
                width: 9,
            },
            mm: {
                width: 229,
                height: 162,
            },
        },
    },
};
