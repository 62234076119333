import axios from "axios";
import {getItem} from "@/util/persistanceStorage";

axios.defaults.baseURL = "/api";


axios.interceptors.request.use(config => {
    const token = (getItem)('accessToken')
    config.headers.Authorization = token ? `Token ${token}` : ''
    return config
})

export default axios;
