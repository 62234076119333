<template>
  <v-navigation-drawer
      border="none"
      permanent
      :width="400"
      color="grey-lighten-5"
  >
    <v-container>
      <v-row dense>
        <v-col cols="12">

          <v-card
              width="366"
              height="800"
          >

            <v-tabs
                v-model="tab"
                bg-color="transparent"
            >
              <v-tab
                  v-for="item in items"
                  :key="item"
                  :value="item"
              >
                {{ item }}
              </v-tab>

            </v-tabs>

            <v-window v-model="tab">
              <v-window-item value="Получатели"
              >
                <v-card
                    flat
                >
                  <app-single-print-contact-list
                      @selectedItem="setRecipientToEnvelope"
                      contact-type="recipient"/>
                </v-card>
              </v-window-item>

              <v-window-item value="Отправители"
              >
                <v-card
                    flat
                >
                  <app-single-print-contact-list
                      @selectedItem="setSenderToEnvelope"
                      contact-type="sender"/>

                </v-card>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-navigation-drawer>
  <v-container
  >
    <v-row
        align="start"
        no-gutters
    >
      <v-col
      >
        <v-card
            min-width="350"
            min-height="800"
        >

          <v-tabs
              bg-color="transparent"
          >
            <v-tab
                value="envelope"
            >
              Конверт
            </v-tab>

          </v-tabs>
          <v-toolbar
              class="pt-5 bg-transparent"
          >
            <v-responsive
                max-width="240"
            >
              <v-select
                  density="compact"
                  :items="Object.keys(ENVELOPE_FORMAT)"
                  item-title="name"
                  return-object
                  prefix="Тип конверта:"
                  v-model="envelopeFormat"
                  single-line
                  hide-details
                  variant="outlined"
                  class="mx-4"
                  @update:modelValue="drawPreviewEnvelope"
              ></v-select>
            </v-responsive>
            <v-btn
                prepend-icon="mdi-printer"
                variant="outlined"
                id="download"
                @click="createPdf">
              Печать
            </v-btn>
          </v-toolbar>

          <v-card-item>
            <v-responsive
                max-width="600"
                class="ma-1 mb-10 elevation-2"
            >
              <canvas id="envelope">Update browser</canvas>
            </v-responsive>
            <v-toolbar
                class="pt-5 bg-transparent"
            >
              <v-list-subheader>Шрифт</v-list-subheader>
              <v-responsive
                  max-width="120"
              >
                <v-text-field
                    label="Высота "
                    v-model="fontSizeContact"
                    type="number"
                    style="width: 70px"
                    density="compact"
                    hide-details
                    variant="outlined"
                    single-line
                    @change="drawPreviewEnvelope"
                ></v-text-field>
              </v-responsive>

              <v-list-subheader>Интервал</v-list-subheader>

              <v-responsive
                  max-width="200"
              >
                <v-text-field
                    label="Line height"
                    v-model="lineHeight"
                    type="number"
                    style="width: 70px"
                    density="compact"
                    hide-details
                    variant="outlined"
                    single-line
                    @change="drawPreviewEnvelope"
                ></v-text-field>
              </v-responsive>

              <v-switch
                  v-model="indexBlock"
                  color="success"
                  hide-details
                  defaults-target=true
                  label="Блок индекса"
                  @update:modelValue="drawPreviewEnvelope"
              ></v-switch>
            </v-toolbar>
            <v-container fluid>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >

                  <v-switch
                      v-model="senderBlockView"
                      color="success"
                      hide-details
                      true-value="underline"
                      false-value="text"
                      defaults-target="underline"
                      label="Разлиновка блока отправителя"
                      @update:modelValue="drawPreviewEnvelope"
                  ></v-switch>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                >
                  <v-switch
                      v-model="recipientBlockTitle"
                      color="success"
                      hide-details
                      defaults-target=true
                      label="Заголовок блока получателя"
                      @update:modelValue="drawPreviewEnvelope"
                  ></v-switch>
                </v-col>

              </v-row>
              <v-row>

                <v-col
                    cols="12"
                    sm="6"
                >
                  <v-switch
                      v-model="senderBlockTitle"
                      color="success"
                      hide-details
                      defaults-target=true
                      label="Заголовок блока отправителя"
                      @update:modelValue="drawPreviewEnvelope"
                  ></v-switch>
                </v-col>


                <v-col
                    cols="12"
                    sm="6"
                >

                  <v-switch
                      v-model="recipientBlockView"
                      color="success"
                      hide-details
                      true-value="underline"
                      false-value="text"
                      defaults-target="underline"
                      label="Разлиновка блока получателя"
                      @update:modelValue="drawPreviewEnvelope"
                  ></v-switch>
                </v-col>


              </v-row>

            </v-container>
          </v-card-item>

        </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import {
  BLOCK_TYPE,
  BLOCK_VIEW,
  drawContactBlock,
  drawEnvelopeBody,
  drawStamp,
  drawTextBlockTitle,
  ENVELOPE_FORMAT,
  PAGE_SIZES
} from "@/util/canvas-util";
import AppSinglePrintContactList from "@/components/SinglePrintContactList";
import {jsPDF} from "jspdf";

export default {
  name: "AppSinglePrintView",
  components: {AppSinglePrintContactList},
  data() {
    return {
      BLOCK_VIEW,
      ENVELOPE_FORMAT,
      PAGE_SIZES,
      BLOCK_TYPE,
      tab: 'Получатели',
      items: [
        'Получатели', 'Отправители'
      ],


      from: "От кого:",
      to: "Кому:",

      sender: {
        name: "",
        address: "",
        index: "",
      },

      recipient: {
        name: "",
        address: "",
        index: "",
      },

      envelopeFormat: "c5",
      recipientBlockView: "underline",
      recipientBlockTitle: true,
      senderBlockView: "underline",
      senderBlockTitle: true,

      indexBlock: true,
      lineHeight: 24,
      printDpi: 300,
      webDpi: 72,
      fontSizeContact: 14,
      fontSizeIndex: 48,
      ratio: null
    }
  },
  computed: {
    envelopeBigIndex() {
      return this.recipient.index.length == 0 ? '$$$$$$' : this.recipient.index
    },
    fontContactBlock() {
      return `${this.fontSizeContact * this.ratio}px Arial`
    },
    fontIndexBlock() {
      return `${this.fontSizeIndex * this.ratio}px Postindex`
    },
  },
  methods: {
    createImg() {
      let link = document.createElement('a');
      link.download = 'filename.png';
      link.href = document.getElementById('envelope').toDataURL()
      link.click();
    },
    setSenderToEnvelope(contact) {
      this.sender.name = (contact) ? contact.name : ''
      this.sender.address = (contact) ? contact.address : ''
      this.sender.index = (contact) ? contact.index : ''
      this.drawPreviewEnvelope()
    },
    setRecipientToEnvelope(contact) {
      this.recipient.name = (contact) ? contact.name : ''
      this.recipient.address = (contact) ? contact.address : ''
      this.recipient.index = (contact) ? contact.index : ''

      this.drawPreviewEnvelope()
    },
    createPdf() {
      let canvas = document.createElement('canvas');
      this.drawEnvelope(canvas, this.printDpi)
      let imgData = canvas.toDataURL("image/jpeg", 1.0);

      let pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [ENVELOPE_FORMAT[this.envelopeFormat].envelopeSize.inch.width, ENVELOPE_FORMAT[this.envelopeFormat].envelopeSize.inch.height]
      });
      // const imgProps= pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      pdf.autoPrint();
      window.open(pdf.output('bloburi'), '_blank')
      // pdf.save("envelope.pdf");
    },
    drawPreviewEnvelope() {
      let envelope = document.getElementById("envelope")
      this.drawEnvelope(envelope, this.webDpi);
    },
    drawEnvelope(canvas, dpi) {
      this.ratio = drawEnvelopeBody(canvas, dpi, ENVELOPE_FORMAT[this.envelopeFormat])
      let context = canvas.getContext('2d')
      context.fillStyle = 'rgba(0,0,0,1)';
      drawStamp(context, ENVELOPE_FORMAT[this.envelopeFormat], this.ratio)
      if(this.indexBlock){
        this.drawIndexBlock(context, ENVELOPE_FORMAT[this.envelopeFormat])
      }

      this.drawSenderBlock(
          context,
          ENVELOPE_FORMAT[this.envelopeFormat].senderBlock.x * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat].senderBlock.y * this.ratio
      )
      this.drawRecipientBlock(
          context,
          ENVELOPE_FORMAT[this.envelopeFormat].recipientBlock.x * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat].recipientBlock.y * this.ratio
      )
    },
    drawIndexBlock(context, envelopeFormat) {
      let index = this.envelopeBigIndex
      context.font = this.fontIndexBlock;
      context.fillText(`$${index}`, envelopeFormat.indexBlock.x * this.ratio, envelopeFormat.indexBlock.y * this.ratio);
    },
    drawSenderBlock(context, x, y) {
      let textBlock = this.drawSenderBlockName(context, x, y)
      this.drawSenderBlockAddress(context, x, textBlock.y)
    },
    drawSenderBlockName(context, x, y) {
      let tab = this.senderBlockTitle ? drawTextBlockTitle(context, this.from, x, y, this.ratio) : 0
      context.font = this.fontContactBlock;

      return drawContactBlock(               // return coordinate of bottom right corner of block
          context,
          this.sender.name, tab,
          x, y,
          this.lineHeight * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat],
          BLOCK_TYPE.senderBlock,
          BLOCK_VIEW[this.senderBlockView],
          this.ratio
      )
    },
    drawSenderBlockAddress(context, x, y) {
      context.font = this.fontContactBlock;
      drawContactBlock(
          context,
          this.sender.address, 0,
          x, y,
          this.lineHeight * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat],
          BLOCK_TYPE.senderBlock,
          BLOCK_VIEW[this.senderBlockView],
          this.ratio,
          this.sender.index)
    },
    drawRecipientBlock(context, x, y) {
      let textBlock = this.drawRecipientBlockName(context, x, y)
      this.drawRecipientBlockAddress(context, x, textBlock.y)
    },
    drawRecipientBlockName(context, x, y) {
      let tab = this.recipientBlockTitle ? drawTextBlockTitle(context, this.to, x, y, this.ratio) : 0
      context.font = this.fontContactBlock;
      return drawContactBlock(              // return coordinate of bottom right corner of block
          context,
          this.recipient.name, tab,
          x, y,
          this.lineHeight * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat],
          BLOCK_TYPE.recipientBlock,
          BLOCK_VIEW[this.recipientBlockView],
          this.ratio
      )
    },
    drawRecipientBlockAddress(context, x, y) {
      context.font = this.fontContactBlock;
      drawContactBlock(
          context,
          this.recipient.address, 0,
          x, y,
          this.lineHeight * this.ratio,
          ENVELOPE_FORMAT[this.envelopeFormat],
          BLOCK_TYPE.recipientBlock,
          BLOCK_VIEW[this.recipientBlockView],
          this.ratio,
          this.recipient.index)
    },
  },

  async mounted() {
    let f = new FontFace('Postindex', 'url("/postindex.ttf")');
    await f.load().then(function (font) {
      document.fonts.add(font)
    })
    this.drawPreviewEnvelope()
  }
}
</script>