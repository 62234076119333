import {createRouter, createWebHistory} from 'vue-router'
//import contactListView from "@/views/ContactListView";
import singlePrintView from "@/views/SinglePrintView";

const routes = [
    {
        path: '/',
        name: 'Single print',
        component: singlePrintView,
        meta: {
            title: "Post Address Book"
        }
    }
/*    {
        path: '/',
        name: 'contactList',
        component: contactListView,
        meta: {
            title: "Post Address Book"
        }
    },
    {
        path: '/single-print',
        name: 'Single print',
        component: singlePrintView,
        meta: {
            title: "Single print"
        }
    }*/

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
})

export default router
