<template>
  <app-error-message v-if="error"/>
  <v-data-table
      @click:row="rowClick"
      v-model="selected"
      class="px-2"
      :headers="headers"
      :items="contactList"
      :search="search"
      item-value="_id"
      select-strategy="single"
      height="524"
      hover
      return-object
      show-select
      fixed-header
      items-per-page="-1"
  >

    <template v-slot:top>
      <v-toolbar
          class="pt-5 bg-transparent"
      >
        <v-text-field
            v-model="search"

            placeholder="Поиск"
            density="compact"
            variant="outlined"
            single-line
            hide-details
            append-inner-icon="mdi-magnify"
            class="ml-2 mr-4"
        ></v-text-field>
        <!--        <v-spacer></v-spacer>-->
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ props }">
            <v-btn
                prepend-icon="mdi-plus"
                variant="outlined"
                v-bind="props"
            >
              Добавить
            </v-btn>
          </template>

          <app-contact-form
              :initial-values="editedItem"
              :errors="validationErrors"
              :is-submitting="isSubmitting"
              :title="formTitle "
              @contactReject="close"
              @contactSubmit="save">
          </app-contact-form>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Вы уверены что хотите удалить этот контакт?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Отмена</v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">Удалить</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
    <v-responsive width="50">
      <v-icon
          size="small"
          class="me-2"
          @click="editItem(item.raw)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          size="small"
          @click="deleteItem(item.raw)"
      >
        mdi-delete
      </v-icon>
    </v-responsive>
    </template>

    <template #bottom v-if="!showDataTableFooter"></template>

    <template v-slot:no-data>
      <tr>
        <td class="d-inline-block">Ничего не найдено</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/contact-list";
import {actionTypes as contactActionTypes, mutationTypes} from "@/store/modules/contact";
import AppErrorMessage from "@/components/ErrorMessage";
import AppContactForm from "@/components/ContactForm";

export default {
  name: "AppSinglePrintContactList",
  components: {AppContactForm, AppErrorMessage},
  props: {
    contactType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDataTableFooter: false,
      search: '',
      selected: [],
      dialog: false,
      dialogDelete: false,
      itemsPerPage: 10,
      contactList: [],
      editedIndex: -1,
      editedId: '',
      editedItem: {
        name: '',
        address: '',
        index: '',
      },
      defaultItem: {
        name: '',
        address: '',
        index: '',
      },
      headers: [
        {
          title: 'Название контакта',
          align: 'start',
          key: 'name',
        },
        {title: '', align: 'end', key: 'actions', sortable: false},

      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.contactList.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
      isSubmitting: state => state.contact.isSubmitting,
      error: state => state.contactList.error,
      validationErrors: state => state.contact.validationErrors
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'Создание контакта' : 'Редактирование контакта'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    selected(val) {
      this.$emit('selectedItem', val[0])
    },
    isLoggedIn(val) {
      val? this.refreshList(): this.contactList=[]
    }
  },
 created() {
    this.fetchContactList(this.contactType);
  },
  methods: {
    fetchContactList(contactType) {
      this.$store.dispatch(actionTypes.getContactList).then(contactList => {
        this.contactList = contactList.filter(function (el) {
          return el.type === contactType
        })
      })
    },

    refreshList() {
      this.fetchContactList(this.contactType);
    },

    addContactType(contactInput) {
      contactInput.type = this.contactType
    },

    editItem(item) {
      this.editedIndex = this.contactList.indexOf(item)
      this.editedId = item._id
      this.editedItem.name = item.name
      this.editedItem.address = item.address
      this.editedItem.index = item.index
      this.dialog = true
    },

    deleteItem(item) {
      // this.editedItem = Object.assign({}, item)
      this.editedIndex = this.contactList.indexOf(item)
      this.editedId = item._id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.dispatch(contactActionTypes.deleteContact, {slug: this.editedId}).then(() => {
        this.refreshList()
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$store.commit(mutationTypes.clearValidationError)
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.editItem = ''
      })
    },

    save(contactInput) {
      this.addContactType(contactInput)
      if (this.editedIndex > -1) {
        const slug = this.editedId
        this.$store.dispatch(contactActionTypes.updateContact, {slug, contactInput}).then(item => {
          this.$store.dispatch(contactActionTypes.getContact, {slug: item._id}).then(updatedItem => {
            Object.assign(this.contactList[this.editedIndex], updatedItem)
            this.close()
          })
        })
      } else {
        console.log("Item", this.editedItem)
        this.$store.dispatch(contactActionTypes.createContact, {contactInput: contactInput}).then((newItem) => {
          this.contactList.push(newItem)
          this.close()
        })
      }
    },
    rowClick (item, row) {
      let val = row.item.value;
      this.selected.length = 0;
      this.selected.push(val);
      this.$emit('selectedItem', val)
    },
  },
}
</script>
<style>
.v-data-table-column--align-start{
  word-break: break-all;
}
</style>