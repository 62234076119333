import axios from "@/api/axios";

const getAllContacts = () => {
  return axios.get("/contacts");
};

const getContact = (slug) => {
  return axios.get(`/contacts/${slug}`).then((response) => response.data.data);
};

const createContact = (contactInput) => {
  return axios
    .post("/contacts", contactInput)
    .then((response) => response.data.data);
};

const updateContact = (slug, contactInput) => {
  return axios
    .put(`/contacts/${slug}`, contactInput)
    .then((response) => response.data.data);
};

const deleteContact = (slug) => {
  return axios.delete(`/contacts/${slug}`);
};

export default {
  getAllContacts,
  getContact,
  createContact,
  updateContact,
  deleteContact,
};
