import {createStore} from "vuex";
import contactList from "@/store/modules/contact-list";
import contact from "@/store/modules/contact";
import auth from "@/store/modules/auth";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        contactList,
        contact,
        auth
    },
});
