<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form
          v-model="isValidationPassed">
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <app-validation-errors
                v-if="errors"
                :validation-errors="errors"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-text-field
                v-model="name"
                label="Название контакта"
                variant="outlined"
                :counter="68"
                :rules="[rules.required, rules.name]"
                clearable
                type="text"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-text-field
                v-model="address"
                label="Адрес"
                variant="outlined"
                clearable
                :counter="102"
                :rules="[rules.required, rules.address]"
                type="text"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-text-field
                v-model="index"
                label="Индекс"
                variant="outlined"
                clearable
                :counter="6"
                :rules="[rules.required, rules.index]"
                type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="blue-darken-1"
          variant="text"
          @click="onReject"
      >
        Отмена
      </v-btn>
      <v-btn
          :disabled="!isValidationPassed"
          color="blue-darken-1"
          variant="text"
          @click="onSubmit"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppValidationErrors from "@/components/ValidationErrors";

export default {
  name: "AppContactForm",
  components: {AppValidationErrors},
  props: {
    title: {
      type: String,
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    },
    isSubmitting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isValidationPassed: false,
      rules: {
        required: value => !!value || 'Field is required',
        name:value => (value && value.length <= 68) || 'Name must be less than 28 characters',
        address:value => (value && value.length <= 102) || 'Address must be less than 56 characters',
        index:value => (value && value.toString().length === 6) || 'Index must be exactly 6 characters',
      },

      formTitle: this.title,
      name: this.initialValues.name,
      address: this.initialValues.address,
      index: this.initialValues.index,
    }
  },
  methods: {
    onSubmit() {
      if (!this.isValidationPassed) return
      const form = {
        name: this.name,
        address: this.address,
        index: this.index
      }
      this.$emit('contactSubmit', form)
    },
    onReject() {

      this.$emit('contactReject')
    }
  }
}
</script>